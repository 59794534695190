<template>
  <div class="icons position-fixed bottom-0 end-0 me-2 mb-2 d-flex flex-column">
    <!-- 高消費卡友生日禮 立即搶購 -->
    <a href="#" v-if="showTop && isCardBTD" @click.prevent="goToGift">
      <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/cardBTDgift/order.svg" alt="高消費卡友生日禮立即搶購" class="d-block cardBuy mb-3 img-fluid">
    </a>
    <!-- 高消費卡友生日禮 立即搶購 end -->
    <!-- 試用包 立即索取 -->
    <router-link to="/event/applytrials" v-if="showTop && isTrials">
      <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/demand.svg" alt="試用包立即索取" class="d-block cardBuy mb-3 img-fluid">
    </router-link>
    <!-- 試用包 立即索取 end-->
    <!-- line 好友領點數 -->
    <div class="position-relative">
      <a v-if="!isCloseLine && !isTrials" href="#" class="position-absolute delete-icon" @click.prevent="closeLinePromotion"
        ><img
          src="@/assets/img/line_delete.png"
          alt="line contact"
          class="d-block mb-3 img-fluid"
      /></a>
      <a v-if="!isCloseLine && !isTrials" href="https://page.line.me/agd3292i?openQrModal=true" class=""
        ><img
          src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/line/line_promotion.png"
          alt="line contact"
          class="d-block mb-3 social img-fluid"
      /></a>
    </div>
    <!-- line 好友領點數 end -->
    <!-- go top -->
    <a href="#" class="w-100" @click.prevent="scollToTop" v-if="showTop && !isCardBTD"
      ><img
        src="../assets/img/Icons-06.svg"
        alt="go to top"
        class="d-block mb-3 mx-auto"
    /></a>
    <!-- go top end -->
  </div>
</template>

<script>
// import moment from 'moment'

export default {
  data () {
    return {
      showTop: false,
      isCloseLine: false
    }
  },
  watch: {
    // ? 監聽路由當進入搜尋頁/館別頁啟動資料計數器
    '$route' (to) {
      if (to.fullPath.match('/productboard/productList/')) {
        this.dataflow = true
        this.dataflowSearch = false
      } else {
        this.dataflow = false
        this.dataflowSearch = false
      }

      // else if (to.fullPath.match('/productboard/productsearch/')) {
      //   this.dataflowSearch = true
      //   this.dataflow = false
      // }
    }
  },
  methods: {
    scollToTop () {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    },
    showScroll () {
      const windowY = window.scrollY//* 捲軸高度
      const innerHeight = window.innerHeight//* 視窗高
      if (windowY > innerHeight) {
        this.showTop = true
      } else {
        this.showTop = false
      }
    },
    closeLinePromotion () {
      this.isCloseLine = true
      sessionStorage.setItem('closeLine', true)
    },
    // checkLinePromotionExpire () {
    //   const now = moment().format('YYYY/MM/DD HH:mm:ss')
    //   if (moment(now, 'YYYY/MM/DD HH:mm:ss').isAfter('2024/12/31 23:59:59')) {
    //     this.isCloseLine = true
    //   }
    //   if (moment(now, 'YYYY/MM/DD HH:mm:ss').isBefore('2022/06/13 15:30:00')) {
    //     this.isCloseLine = true
    //   }
    // },
    goToGift () {
      // 高消費卡友生日禮 - 移至商品區
      const cardPrd = document.querySelector('.cardBirthdayGift')
      if (cardPrd) {
        window.scrollTo({ top: cardPrd.offsetTop - 100, behavior: 'smooth' })
      }
    }
  },
  computed: {
    //* 是否在高消費卡友生日禮頁面
    isCardBTD () {
      return this.$route.fullPath.includes('/cardbirthdaygift')
    },
    //* 是否在試用包頁面
    isTrials () {
      return this.$route.fullPath.includes('/event/trials')
    }
  },
  mounted () {
    // this.checkLinePromotionExpire()
    if (sessionStorage.getItem('closeLine')) {
      this.isCloseLine = true
    }
    window.addEventListener('scroll', this.showScroll)
  },
  unmounted () {
    window.removeEventListener('scroll', this.showScroll)
  }
}

</script>

<style lang="scss" scoped>
  .countIcon,.countIcon2{
    background-color: #adb5bd;
    color: #ddd;
    border-radius: 50%;
    height: 97px;
    width: 97px;
    // filter: drop-shadow(.3rem .2rem .3rem   #EDEDED);
    @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
    @media (max-width: 576px) {
    width: 50px;
    height: 50px;
  }

  }
  .social {
    width: 150px !important;
    height: auto !important;
    @media (max-width: 768px) {
      width: 100px !important;
    }
  }
  .delete-icon{
    right: 0px;
    top:-10px;
    @media (max-width: 768px) {
      top:-12px;
    }
    img{
      width: 25px;
      height: 25px;
      @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
    }
  }
  /* 高消費卡友生日禮 立即訂購 */
  /* 試用包 立即索取 */
  .cardBuy{
    width: 110px;
    height: auto;
    margin: auto;
    @media (max-width: 768px) {
      width: 90px;
    }
  }
</style>
