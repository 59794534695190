<!-- eslint-disable quotes -->
<template>
  <div>
    <div v-if="!isLoading">
      <LoadingProduct></LoadingProduct>
    </div>
    <div v-else>
      <div class="container">
        <div class="row justify-content-center mt-3 mt-lg-5">
          <div class="col-lg-10">
            <!-- 商品描述. -->
            <div class="productDescriptionContent mx-auto mb-3">
              <div>
                <router-link :to="`/checkoutboard/2025birthdaycheckoutcart`">
                  <div
                    class="text-dark descriptionContent"
                    v-html="product.Description"
                  ></div>
                </router-link>
              </div>
            </div>
            <!-- 到第二頁 填寫資料 -->
            <div class="row justify-content-center my-3 my-lg-5">
              <div class="col-lg-6">
                <div class="d-grid gap-2">
                  <router-link
                    :to="`/checkoutboard/2025birthdaycheckoutcart`"
                    class="btn btn-primary rounded-0 fs-6 fs-lg-5 border border-primary"
                    type="button"
                  >
                    立即購買
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingProduct from '@/views/Loading/LoadingProduct.vue'
import ProductSvc from '@/service/product-service.js'

export default {
  data () {
    return {
      product: {},
      productId: 'P241206000001',
      productNum: 1, //* 商品數量
      isLoading: false, // ?進場動畫開關
      optionSelected: '',
      title: '' //* 存放各頁面title
    }
  },
  components: {
    LoadingProduct
  },
  methods: {
    GetBirthProduct_2025 () {
      // this.productId = this.$route.params.id
      ProductSvc.GetBirthProduct_2025(this.productId).then((data) => {
        this.isLoading = true
        if (!data) {
          return
        }
        this.product = data
        // * GA追蹤程式
        this.title.textContent = `papaWash - ${this.product.Name}` // ?title更新為當頁商品
        this.track() // ? 呼叫vue-gTag傳入 titleF
        if (this.product.Options.length > 0) {
          this.optionSelected = this.product.Options[0]
          if (this.product.Options[0].Stock === 0) {
            this.productNum = 0
          }
        }
        setTimeout(() => {
          const url = document.querySelectorAll('.descriptionContent img')
          url.forEach((item) => {
            item.classList.add('w-100')
          })
        }, 500)
      })
    },
    track () {
      // ?GA追蹤
      this.$gtag.set({
        page_title: `papaWash - ${this.product.Name}`
        // send_to: '<MEASUREMENT_ID>',
        // page_path: `papaWash - ${this.data.name}`
      })
    }
  },

  mounted () {
    this.GetBirthProduct_2025()
    if (document.body.clientWidth < 992) {
      window.addEventListener('scroll', this.addCartScroll)
    }
    this.title = document.querySelector('title') // ?取得 <title> DOM 元素 for GA
  },
  unmounted () {
    if (document.body.clientWidth < 992) {
      window.removeEventListener('scroll', this.addCartScroll)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/pages/_product";
</style>
